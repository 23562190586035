import AddSubs from 'assets/images/support/add_subs.jpg';
import Duplicate from 'assets/images/support/duplicate.jpg';
import HomePageImage from 'assets/images/support/addingImagesHomeAnimation.gif';
import AddPhoneNumber from 'assets/images/support/addingPhoneAnimated.gif';
import AddRichText from 'assets/images/support/addingRichTextAnimation.gif';
import AddWebsite from 'assets/images/support/addingWebsiteAnimated.gif';
import CheckIn from 'assets/images/support/TapToCheckIn14Sept2020.gif';
import ShowIn from 'assets/images/support/TapToShowIn14Sept2020.gif';


export const SUPPORT_ITEMS_FIRST_COLUMN = [
  {
    title: 'About data',
    questions: [
      {
        question: 'Do you record or access any of our Event data?',
        answer: 'No. We do not store any attributable or personal data, except where required to do so for COVID Contact Trace. Data held for this purpose is done so in compliance with GDPR and is deleted automatically after 21 days.',
      },
      {
        question: 'Do you rent or sell any Event data?',
        answer: 'No. We do not store any data at all about your Attendees.',
      },
    ],
  },
  {
    title: 'About the E-vnt App',
    questions: [
      {
        question: 'Is the E-vnt app free?',
        answer: 'Yes. It will always be free to Attendees. Event brands have subscriptions which pays for the app.',
      },
      {
        question: 'Is the E-vnt app available for IOS and Android?',
        answer: 'Yes. It is available in both app stores. See the links at the top and bottom of this page',
      },
      {
        question: 'Will every Event see every push message?',
        answer: 'When you send messages to Attendees you can select to include a Push notification, and you can also limit the Push to within a radius of your location - enabling only checked in or local Attendees to see the Push. For more information look under About Messaging section. Attendees also have the option to manage notifications on thier own devices.',
      },
      {
        question: 'How often does the app update with my content?',
        answer: 'Some of the content may be cached on Attendees phones, some other types of content will be refreshed when your Attendees tap to view the content, or pull to refresh.',
      },
    ],
  },
  {
    title: 'About Images',
    questions: [
      {
        question: 'What is the spec for images in Image Messages?',
        answer: `
        <p>We recommend you provide images that are 2208 x 2208 pixels, saved for web as a .jpg.</p>
        <p>You should save them so the file size is reasonably small, around 300k. The images will be cropped left and right depending upon the device they are viewed on.</p>
        <p>It is best to have your main content within the central 700 pixels wide, content outside this area may be cropped depending upon device.</p>
        `,
      },
      {
        question: 'What is the spec for Home page images?',
        answer: `
        <p>We recommend you provide images that are 2208 x 2208 pixels, saved for web as a .jpg.</p>
        <p>You should save them so the file size is reasonably small, around 300k. The images will be cropped left and right depending upon the device they are viewed on.</p>
        <p>It is best to have your main content within the central 700 pixels wide, content outside this area may be cropped depending upon device.</p>
       `,
      },
      {
        question: 'What is the spec for PDFs I want to attach to a message?',
        answer: 'You can attach PDFs to Messages that Attendees can view direct in app using our in-app PDF reader. Please ensure your PDFs are as small file size as possible - and no larger than 400k. Turn off editing functions and keep the quality suitable for mobile screens.',
      },
    ],
  },
  {
    title: 'Adding more Properties (Hotels, Resorts, Attractions and Tours)',
    questions: [
      {
        question: 'What is a Event?',
        answer: 'A Event is the name of your Hotel, Resort, Attraction or Tour.',
      },
      {
        question: 'I have added one Event, can I add more?',
        answer: `
        <p>Yes. Simply add a new Event on My Home (you may need a subscription)</p>
        <img src="${AddSubs}" alt="Subscribe to our Event app" />
        `,
      },
      {
        question: 'I have one Event already, and some pages are the same for all new ones - do I have to add all the content again for each one?',
        answer: `
        <p>No! You can duplicate a Event which will copy all your pages. You can then make individual changes as you need to.</p>
        <img src="${Duplicate}" alt="Duploicate Event App Pages" />
        `,
      },
      {
        question: 'How many pages or tabs can I add to each Event?',
        answer: 'As many as you wish. We will automatically add a More tab to list any pages that dont fit on the navigation bar at the bottom of your app.',
      },
    ],
  },
  {
    title: 'Admin on mobile and tablets',
    questions: [
      {
        question: 'Does the Admin section work on mobiles and tablets?',
        answer: 'Yes. The whole Admin has been designed to work on any connected device, including Mobiles and Tablets.',
      },
      {
        question: 'Is there a specific app I need to download to manage Admin?',
        answer: `
        <p>No! This is not necessary as we have optimised the site for use on Mobiles and Tablets. </p>
        <p>However, you can download and save the icon to your device Home Screen providing you with easy access right on your mobile!</p>
        `,
      },
      {
        question: 'How do I add Admin to my iPhone for easy access?',
        answer: `
        <p>Open Safari and go to E-vnt.com. Scroll up until you see a bar appear at the bottom of your screen with some icons in it.</p>
        <p>You should see in the middle a square box with an arrow pointing up. Tap this, and scroll down until you see an option to Add to Home Screen with a square +.</p>
        <p>Tap Add in the top right. You are done!</p>
        `,
      },
      {
        question: 'How do I add Admin to my Android phone for easy access?',
        answer: 'Open Chrome and go to E-vnt.com. Tap the 3 dots icon top right, and select Add to Home screen, tap Add. You are done!',
      },
    ],
  },
  {
    title: 'Telling Attendees about your app',
    questions: [
      {
        question: 'When will my app be available to Attendees?',
        answer: 'Once you have added your app to E-vnt, it is immediately available to your Attendees.',
      },
      {
        question: 'What do Attendees need to see my app?',
        answer: 'They just need a Apple IOS or Android device.',
      },
      {
        question: 'How can my Attendees get my app?',
        answer: `
        <p>Attendees can get your app in several ways;</p>
        <p><strong>1.</strong> They can download E-vnt app from the stores and search using the search terms you have added to your Properties.</p>
        <p><strong>2.</strong> You can provide them with the QR code for each Event on your website or confirmation emails. You can add them to printed media (like posters at reception or labels in rooms).</p>
        <p>If they already have E-vnt on their devices, your Event app automatically opens.</p>
      `,
      },
      {
        question: 'What happens if my Attendees do not have the E-vnt app on their phones?',
        answer: `
        <p>Attendees can just scan the QR code with their mobile phone camera.</p>
        <p>Our clever tech then automatically takes them to the correct app store, downloads E-vnt which then opens immediately on your own Event app!</p>
        <p>Check out this video showing you how smart and quick the app opens, even when not already installed!</p>
        `,
      },
    ],
  },
];

export const SUPPORT_ITEMS_SECOND_COLUMN = [
  {
    title: 'Attendee Information',
    questions: [
      {
        question: 'What is Attendee Information?',
        answer: `
        <p>Make available all information for Attendees, right in their pockets.</p>
        Any venue and event information including registration and travel, facilities, local partners, on site messaging, timetables and much more.</p>
        `,
      },
      {
        question: 'How do I add Event Information content?',
        answer: `
        <p>Click on the Pencil icon to edit your Event Information. The is a full text editor you can use to format text, and add images or video.</p>
        <p>You can add Sections and Subsections to group your content into easy to read sections (like chapters is a book).</p>
        <p>You can toggel content invisible/visible by tapping the Eye icon - enabling you to work on your Event Information until you are ready to publish.</p>
        <p><strong>Best viewed Full Screen</strong>
        <p></p>
        <iframe width="100%" height="300" src="https://www.youtube.com/embed/RwgOfkKhZoc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        `,
      },
    ],
  },
  {
    title: 'Adding Menus, choosing colors and fonts, images and Contact Trace check-in',
    questions: [
      {
        question: 'How many menus can we add?',
        answer: `
        <p>You can add as many menus as you like. Each menu also has sections, so you can choose to add all your F&B in one menu or separate for diners convenience.</p>
      `,
      },
      {
        question: 'How do I add a menu with our colors and menu items?',
        answer: `
        <p>Watch this walkthru which shows how easy it is to add your F&B menu. There is a full text editor, you can add images and video as well as customise colors and fonts.</p>
        <p><strong>Best viewed Full Screen</strong>
        <p></p>
        <iframe width="100%" height="300" src="https://www.youtube.com/embed/U15f2LdEnhQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        `,
      },
      {
        question: 'How does your Contact Trace work?',
        answer: `
        <p>You can choose to add an optional Contact Trace  check in to your menus.</p>
        <p>We will collect the contact data and make it available to you for 21 days.</p>
        <p>Check the Contact Trace option in Menu editing panel too add to your menus.</p>
        <p>Once a diner has tapped to check in, servers can ask to view the Check In (green) screens to ensure the party has done so.</p>
        `,
      },
      {
        question: 'If I have to alert attendees to potential infection or to find out more, how do I do this?',
        answer: `
        <p>There is more information about sending a Contact Trace message under 'About Messaging'</p>
        `,
      },
      {
        question: 'How do attendees Check In?',
        answer: `
        <p>Once you have added the option in Admin, Attendees will see a red bar at the top of the menu, tapping this will enable them to Check In.</p>
        <img src="${CheckIn}" alt="Format Images in your Event Information App" />
        `,
      },
      {
        question: 'How do we make sure an attendee has checked in?',
        answer: `
        <p>Once they do, the button at the top of the menu changes to green. They can tap this and show you the Checked In screen, showing date/time/party size. Attendees can check out or we will do so automatically and the days end.</p>
        <img src="${ShowIn}" alt="Format Images in your Event Information App" />
        `,
      },
    ],
  },
  {
    title: 'Configuring Your Brand',
    questions: [
      {
        question: 'How do I add my organisation name, logo, colours and typeface?',
        answer: `
        <p>You should be automatically taken to a page on Sign Up where you can add your logo, select your brand colours and font you would like to use.</p>
        <p>You can update this anytime by clicking on Home and selecting the Configure Brand button below the app preview.</p>
        <p>The colors chosen in Configure Branding are reflected in the app Preview. You can also choose to add custom colors to the Rich Text Format (RTF) Text Editor.</p>
        <p><strong>Best viewed Full Screen</strong>
        <p></p>
        <iframe width="100%" height="300" src="https://www.youtube.com/embed/Aj-KtEKxVsI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      `,
      },
      {
        question: 'Once I have Configured Brand - how do I add my Events?',
        answer: `
        <p>Once you have Configured brand, you should be asked to add an Event.</p>
        <p>You can add 1 x for a free period and subscribe once ended. Further Events within the same brand require a subscription.</p>
        <p>Go to My Home and add a subscription.</p>
        `,
      },
    ],
  },
  {
    title: 'About Messaging',
    questions: [
      {
        question: 'What is the difference between Messaging and a Quick Message?',
        answer: `
        <p>A quick message is just a Push notification, with no content associated with it.</p>
        <p>A Quick message will be sent to everyone with that Event as a favorite. It's really handy to communicate something quickly.</p>
        <p>Messaging has content associated with it, and appears in a tab within your app. An image, a headline, some text and optional PDF or YouTube video can be included.</p>
        `,
      },
      {
        question: 'Do I have to send a Push with a Message?',
        answer: 'You have the option to include a Push notfication if you wish.',
      },
      {
        question: 'Can I limit Push to  my location?',
        answer: 'If you have set your address and range (under Name in admin), you can limit Push to within or outside the range radius of your location. Just select that option when sending a push.',
      },
      {
        question: 'What images and branding are included in the Push message.',
        answer: 'Where allowed by the platform (IOS/Android) we include a logo thumbnail and your message image. For Enterprise Apps your own brand thumbnail is included.',
      },
      {
        question: 'How do I send an Image Message?',
        answer: `
        <p>Prepare your image as a .jpg (see 'About Images"), your headline and text. Go to the right hand menu and tap Messaging. This video shows how to send a message.</p>
        <p>See how you can choose to include a Push, and limit it to the radius set around your location.</p>
        <p><strong>Best viewed Full Screen</strong>
        <p></p>
        <iframe width="100%" height="300" src="https://www.youtube.com/embed/sFvOMAEnhgc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      `,
      },
      {
        question: 'Sending a Quick Message',
        answer: `
        <p>This is just a quick push message to everyone in the Event. Go to the right hand menu and tap Quick Messaging. This sends a Push with a link, you can choose to limit this to only Attendees within your set radius.</p>
        <p><strong>Best viewed Full Screen</strong>
        <p></p>
        <iframe width="100%" height="300" src="https://www.youtube.com/embed/WW8aLN_wuWY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      `,
      },
      {
        question: 'Contact Trace Message',
        answer: `
        <p>You can send a message to any diner based on their check in date and time. You can include links to other pages in your app or to more information on the wider web.</p>
        <p><strong>Best viewed Full Screen</strong>
        <p></p>
        <iframe width="100%" height="300" src="https://www.youtube.com/embed/2p9_n0XKzn4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      `,
      },
    ],
  },
  {
    title: 'About adding content to your app',
    questions: [
      {
        question: 'I have signed up, configured my brand. What next?',
        answer: 'You need to add an Event.',
      },
      {
        question: 'How page Pages or Tabs can I add to each Event?',
        answer: 'As many as you wish. Our system will automatically add a More page to list any more than your 5th page.',
      },
      {
        question: 'What types of pages can I add?',
        answer: `
        <p>There are a number of different page types you can add to each Event.</p>
        <p><strong>Home</strong> - Every app must have a Home page (default). Add one or more images as a gallery (just add as many images as you wish).</p>
        <p><strong>Messages</strong> - (default) Contains your image messages.</p>
        <p><strong>Event Info</strong> - Timetables, menus, reistration, transportation, Emergency procedures - any information attendees might want prior to or during your event.</p>
        <p>Add language translations (subscription required)</p>
        <p><strong>Call</strong> - calls the number you right out of your app.</p>
        <p><strong>Rich Text Format</strong> - adds a dynamic page, with any content you wish (text and images).</p>
        <p><strong>URL website address</strong> - links direct to any page on any website - must be https secure.</p>
        <p><strong>Menu</strong> - create your F&B menus, colors and images, choosing currency, menu items - and Contact Trace check in. </p>
        <p><strong>Facebook</strong> - Make sure you are loged into your Facebook page, then select that option and just add your ID, we'll automatically add your FB feed.</p>
        <p>Contacts directory - add a title, email and phone for any number of contacts (Organisers Office etc)</p>
        <p><strong>We are adding more page types all the time - these are in the pipeline</strong></p>
        `,
      },
      {
        question: 'How do I add an image to my Event home page?',
        answer: `
        <p>Prepare an image and save as a .jpg (see 'About Images' section) and follow this animation to add an image to each Event Home page.</p>
        <img src="${HomePageImage}" alt="Adding images to your Home Page Event app" />
      `,
      },
      {
        question: 'How do I add a phone number so Attendees can call right out of the app?',
        answer: `
        <p>Follow this animation, ensuring you use the international dialing code for your Event.</p>
        <img src="${AddPhoneNumber}" alt="Call right out of the hotel Event app" />
      `,
      },
      {
        question: 'I want to add a stand alone page to the app, with text and images?',
        answer: `
        <p>It's a good idea to write your text first, and save any images as you would for a website. You can format the text as you would expect.</p>
        <img src="${AddRichText}" alt="Adding a rich text page view to your Event app" />
      `,
      },
      {
        question: 'I want to add a page that already exists on the web.',
        answer: `
        <p>As long as the website is hosted securely, and the link you add is 'https' - you can add any page you like. You can switch off the logo that appears too ensuring branding is not duplicated.</p>
        <img src="${AddWebsite}" alt="Add any secure website" />
      `,
      },
    ],
  },

  {
    title: 'Enterprise Apps',
    questions: [
      {
        question: 'How do I get my own app in the app stores?',
        answer: `
        <p>Once you have set up your brand Event and subscribed, just let us know via chat or email you'd like to upgrade to an Enterprise App - there are a few things you will need.</p>
        <p>Please read through the details here.</p>
      `,
      },
      {
        question: 'Your own App and Play Store accounts.',
        answer: `
        <p>You will need to set up developer accounts for both the App Store and the Play Store. They will ask for a small subscription payment. They require you to sign for their TCs, so only an official company representative can do this.</p>
        <p>This ensures you own the apps, they are in your brand accounts in the stores.</p>
        <p>Apple will require an Apple ID and a Developer account.</p>
        <p>Once these are set up and approved, add us as Developers so we can publish your apps in your own accounts. We will show you how to do this.</p>
        `,
      },
      {
        question: 'You will need to send us some images.',
        answer: `
        <p>We will need an app icon (1024 x 1024 pixels) and a splash image (2208 x 2208 pixels), plus some app store images.</p>
        <p>We will send you a Photoshop template for your store images, but if you require any help creating these or other images please just ask us!</p>
        `,
      },
      {
        question: 'It can take a week or two.',
        answer: `
        <p>There are a few hoops to leap through setting up your accounts and getting your store listings sorted, we are here to help.</p>
        <p>The whole process should not take more than a couple of weeks, possibly less.</p>
        `,
      },
    ],
  },
  {
    title: 'Hey, I need to ask something else!',
    questions: [
      {
        question: 'Questions not answered?',
        answer: `
        <p>We're here 24/7 - tap/click the red chat icon below and ask away!</p>
      `,
      },
      {
        question: 'I have a more complicated requirement, how can I contact you?',
        answer: `
        <p>Flip to our home page, and go to the form at the bottom. We'll come back to you asap!</p>
      `,
      },
    ],
  },

];
