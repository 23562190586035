import React from 'react';
import { Helmet } from 'react-helmet';


export default {
  default: (
    <Helmet
      defaultTitle="E-vnt.com - create and publish your own event and exhibition app."
    >
      <meta
        name="keywords"
        content="event, exhibition, app, experience, touchless, documentation, direct, registration"
      />
      <meta
        name="description"
        content="Improve attendee experience with a custom branded event app. Contactless technology services for guests, enhance guest experience and increase safety. Communicate with in app messaging."
      />
    </Helmet>
  ),
  home: (
    <Helmet>
      <title>E-vnt attendee experience app for events, exhibitions and seminars</title>
      <meta
        name="keywords"
        content="attendee, event, exhibition, app, cms, experience"
      />
      <meta
        name="description"
        content="Improve attendee experience with a custom branded event app. Contactless technology services for guests, enhance guest experience and increase safety. Communicate with in app messaging."
      />
    </Helmet>
  ),
  signUp: (
    <Helmet>
      <title>SignUp 14 Days Free Attendee Experience App</title>
      <meta
        name="keywords"
        content="attendee, app, experience, sign, up, no, code"
      />
      <meta
        name="description"
        content="Improve attendee experience with a custom branded event app. Contactless technology services for guests, enhance guest experience and increase safety. Communicate with in app messaging."
      />
    </Helmet>
  ),
  logIn: (
    <Helmet>
      <title>Login to manage your attendee event app content and messaging</title>
      <meta
        name="keywords"
        content="attendee, app, experience, sign, up, no, code"
      />
      <meta
        name="description"
        content="Login to manage added value content, attendee messaging, event information."
      />
    </Helmet>
  ),
  forgotPassword: (
    <Helmet>
      <title>Password recovery for ConciergePro</title>
      <meta
        name="keywords"
        content="password, recovery, reset, attendee, app"
      />
      <meta
        name="description"
        content="Recover your password here to admin your Attendee Experience app."
      />
    </Helmet>
  ),
  aboutUs: (
    <Helmet>
      <title>About Us</title>
      <meta
        name="description"
        content="About Helmet description"
      />
    </Helmet>
  ),
  support: (
    <Helmet>
      <title>Event Attendee App Support 24/7</title>
      <meta
        name="keywords"
        content="documentation, support, messaging, guest, book, direct, booking, chat, ticket, how to, content"
      />
      <meta
        name="description"
        content="Improve attendee experience with a custom branded event app. Contactless technology services for guests, enhance guest experience and increase safety. Communicate with in app messaging."
      />
    </Helmet>
  ),
  privacy: (
    <Helmet>
      <title>Privacy Statement</title>
      <meta
        name="keywords"
        content="guest, data, privacy, policy, GDPR"
      />
      <meta
        name="description"
        content="How we deal with privacy and data for events, clients and attendees."
      />
    </Helmet>
  ),
  terms: (
    <Helmet>
      <title>Terms and Conditions</title>
      <meta
        name="keywords"
        content="terms, conditions, guest, app, event"
      />
      <meta
        name="description"
        content="Our terms for using E-vnt attendee app builder."
      />
    </Helmet>
  ),
  gdpr: (
    <Helmet>
      <title>GDPR Information</title>
      <meta
        name="keywords"
        content="attendee data, gdpr, statement"
      />
      <meta
        name="description"
        content="How we manage attendee data and our gdpr policy."
      />
    </Helmet>
  ),
};
