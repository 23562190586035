const config = {
  firebase: {
    apiKey: 'AIzaSyDFRgD0Ue5qF3lkHMZl1VN-VXmSfmcC6pc',
    authDomain: 'e-vnt-a1f1f.firebaseapp.com',
    projectId: 'e-vnt-a1f1f',
    storageBucket: 'e-vnt-a1f1f.appspot.com',
    messagingSenderId: '335321021670',
    appId: '1:335321021670:web:cbadf68824de5717a94f27',
    measurementId: 'G-TP2HQ2MQ9D',
  },
  stripePublicAPIKey: 'pk_live_51IvhwYGBcZsFl6s69KG1F6yPXux8cx0lJFAtNmEYcn136rne7hn00fQRXt3ExGZfzoTQ2ezjIm1HaOGtaBZ7Dy3500fCoSYT2t',
  facebookAppId: '589215375048594',
  google: {
    apiUrl: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBgeaPg2Gxqk1rD5bjTpQO8_HmsmDO9u-I&libraries=places,geometry',
  },
};


export default config;
