import { createMuiTheme } from '@material-ui/core/styles';

import defaultTheme from '@pro/web-common/theme';


const theme = createMuiTheme({
  ...defaultTheme,
  palette: {
    primary: {
      light: '#88ACCD',
      main: '#4D93D5',
      additional: '#32404D',
      another: '#658099',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    ...defaultTheme.typography,
    fontFamily: 'Fira Sans',
  },
});

export const LOGO_WIDTH = '200px';

export default theme;
