export const UPGRADED_PLAN_FEATURES = [
  {
    id: '1',
    label: 'Unlimited Users',
    description: 'You will be able to add unlimited Users to your Admin - access by Event or access all',
  },
  {
    id: '2',
    label: 'Multi Language Event Information',
    description: 'Automatically translate your Event Information into multiple langauges',
  },
  {
    id: '3',
    label: 'Enhanced Messaging With Image and Push Notifications',
    description: 'Use advanced features to send messages with images, video and PDFs',
  },
  {
    id: '4',
    label: 'Search Terms/Tags',
    description: 'List search terms Attendees can use to find your event app',
  },
  {
    id: '5',
    label: 'Geo Location Push',
    description: 'Limit Push notifications attendees within set radius',
  },
];

export const PLAN_TYPE_FREE_ID = 'free';
export const PLAN_TYPE_SINGLE_ID = 'single';
export const PLAN_TYPE_MULTI_TEN_ID = 'multiTen';
export const PLAN_TYPE_BANK_TRANSFER = 'bankTransfer';

export const ACTIVE_SUBSCRIPTION_LIMIT = null;

export const PLAN_TYPE_PRICES = {
  [PLAN_TYPE_SINGLE_ID]: {
    EUR: {
      price: '115.00',
    },
    GBP: {
      price: '99.00',
    },
    USD: {
      price: '139.00',
    },
  },
};

export const PLAN_TYPES = [
  {
    id: PLAN_TYPE_FREE_ID,
    title: 'FREE Trial',
    canAdd: false,
    features: [
      {
        id: 'products_count',
        label: '1 x Event',
        description: 'Add 1 event during your trial - upgrade to add more',
      },
      {
        id: 'users_count',
        label: '1 x User',
        description: 'Add 1 User to manage your content or upgrade for unlimited Users',
      },
      {
        id: 'versions_count',
        label: '1 x Language',
        description: 'Single language event information. Upgrade to auto translate and add more languages',
      },
      {
        id: 'messaging',
        label: 'Quick Push Messaging',
        description: 'Instantly message attendees via Push Notifications',
      },
      {
        id: 'image_messaging',
        label: 'Image Messaging',
        description: 'Add images and content to the messaging channel in app',
      },
      {
        id: 'menu',
        label: 'F&B Touchless Menu',
        description: 'Add touchless menus to your app',
      },
      {
        id: 'contact_trace',
        label: 'Contact Trace Messaging',
        description: 'Message based on attendee check in time and date, with optional GDPR compliant data collection',
      },
    ],
  },
  {
    id: PLAN_TYPE_SINGLE_ID,
    title: '1 Destination',
    availableProducts: 1,
    canAdd: true,
    getAddButtonLabel: ({ currencySign, price }) => `Add 1 x Destination Hotel, Resort, Attraction or Tour @${currencySign}${price} per month`,
    features: [
      {
        id: 'features',
        label: 'Fully featured app, including;',
        description: 'All features with no limits per Event',
      },
      {
        id: 'users',
        label: 'Unlimited Users',
        description: 'Add unlimited Users to your admin - access by Event or access all',
      },
      {
        id: 'versions_count',
        label: 'Multi Language Event Information',
        description: 'Multi language Event information. Use automated translation and update as you wish',
      },
      {
        id: 'messaging',
        label: 'Quick Push Messaging',
        description: 'Instantly message attendees via Push Notifications',
      },
      {
        id: 'image_messaging',
        label: 'Image Messaging with Push Notifications',
        description: 'Add images and content to the messaging channel in app, and optionally send Push',
      },
      {
        id: 'geo_location',
        label: 'Event messaging based on location',
        description: 'Geo location based Event messaging. Limit messaging to inside or outside radius',
      },
      {
        id: 'menu',
        label: 'F&B Touchless Menu',
        description: 'Add touchless menus to your app',
      },
      {
        id: 'contact_trace',
        label: 'Contact Trace Messaging',
        description: 'Message based on attendee check in time and date, with optional GDPR compliant data collection',
      },
      {
        id: 'search_tags',
        label: 'Search Terms/Tags',
        description: 'List search terms Guests can use to see your Event app',
      },
    ],
  },
  {
    id: PLAN_TYPE_BANK_TRANSFER,
    title: 'Bank Transfer',
    canAdd: false,
  },
];

export const UPGRADE_PLAN_FEATURES = [
  {
    id: 'features',
    label: 'Fully featured app, including;',
    description: 'All features with no limits per Event',
  },
  {
    id: 'users',
    label: 'Unlimited Users',
    description: 'Add unlimited Users to your admin - access by Event or access all',
  },
  {
    id: 'versions_count',
    label: 'Multi Language Event Information',
    description: 'Multi language Event information. Use automated translation and update as you wish',
  },
  {
    id: 'messaging',
    label: 'Quick Push Messaging',
    description: 'Instantly message attendees via Push Notifications',
  },
  {
    id: 'image_messaging',
    label: 'Image Messaging with Push Notifications',
    description: 'Add images and content to the messaging channel in app, and optionally send Push',
  },
  {
    id: 'geo_location',
    label: 'Event messaging based on location',
    description: 'Geo location based Event messaging. Limit messaging to inside or outside radius',
  },
  {
    id: 'menu',
    label: 'F&B Touchless Menu',
    description: 'Add touchless menus to your app',
  },
  {
    id: 'contact_trace',
    label: 'Contact Trace Messaging',
    description: 'Message based on attendee check in time and date, with optional GDPR compliant data collection',
  },
  {
    id: 'search_tags',
    label: 'Search Terms/Tags',
    description: 'List search terms Guests can use to see your Event app',
  },
];
