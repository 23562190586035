import React from 'react';
import { NavHashLink } from 'react-router-hash-link';

import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Link,
} from '@material-ui/core';

import { STORE_LINKS } from 'constants/app';

import SectionImage from 'assets/images/home/eVntHome600x636.png';

import { styles } from './styles';


const StartNowSection = React.memo(() => {
  const classes = styles();

  return (
    <Box
      bgcolor="primary.light"
      pt={10}
      pb={10}
    >
      <Container maxWidth="md">
        <Grid
          container
          justify="space-between"
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h3"
            >
              Sign Up/Login.
            </Typography>

            <Box mt={1}>
              <Typography
                gutterBottom
                variant="h1"
              >
                Create an event experience app for your brand.
              </Typography>
            </Box>

            <Typography>
            Keep attendees informed and updated before, during &amp; after thier visit.
              <Box
                mt={1}
                display="block"
                component="span"
                fontWeight="fontWeightBold"
              >
                Attendee venue &amp; event information, timetables, messaging, push notifications, registration, social media feeds, partner information, vouchers, maps, local treats + much more!
              </Box>
            </Typography>

            <Box
              mt={3}
              mb={[4, 0]}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                to="/sign-up"
                smooth
                offset={-64}
                component={NavHashLink}
              >
                Sign Up Free
              </Button>

              <Typography component="span">
                FREE for 14 days!
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <img
              src={SectionImage}
              alt="hero"
            />

            <Box mt={4}>
              {STORE_LINKS.map(({ id, href, icon }) => (
                <Link
                  key={id}
                  href={href}
                  className={classes.storeLink}
                >
                  <img
                    src={icon}
                    alt={id}
                  />
                </Link>
              ))}
            </Box>
            <Box
              mt={1}
              display="block"
              component="span"
              fontWeight="fontWeightBold"
            >
              E-vnt app is free for your attendees, forever.
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});


StartNowSection.propTypes = {};

StartNowSection.defaultProps = {};


export default StartNowSection;
